import React from "react";
import {PageLayout} from "src/components/templates/PageLayout/PageLayout";
import {Markdown} from "../../templates/Markdown/Markdown";

const pageContent = `
### Snap Challenge

This program is part of Schönherz Qpa 2022, and is a competitive programming style, multi-day competition.
You can earn points that get converted to QPA points at the end.

On most days from **2022.09.20 (everyday at 16:00) - 2021.09.23** we will release a new problem for you to solve.
A practice problem will become available to you at **2022.09.19 16:00**, it won't be worth any points.
You can solve these problems with your favorite programming language (more about submissions in the API guide).
You can work alone or in a team but **QPA points are only awarded to real QPA teams** (We expect teams to correspond to a real Schönherz QPA team).

### Rules
- Requirements (breaking these rules will result in **a ban**)
	- You must have an unoffensive username that is your own (not knowingly used by someone else)
	- You must create your unique problem solution, this doesn't include:
		- the integration with the solutions API (more on this in the API guide)
		- any other tools you might use that could be considered general-purpose ex: libraries, CLI tools, etc... (not made to solve our exact problems), questionable cases will be subject to individual review
	- You must not upload misleading code on purpose after correctly completing a submission (more about this in the API Guide)
- Structure
	- Each problem is worth a number of points depending on its difficulty (xx pts on the website)
	- Individuals are expected to register and then join a team (to earn QPA points)
	- Each individual has their own points separate from the team's points they are in
	- Points gained before joining a team will not count toward a team's overall points
	- Teams can gain points for each individual's correct solution to a problem but the following additional rules apply:
		- Subsequent solutions to the same problem give fewer and fewer points, so the amount gained for a new solution is calculated according to the following formula: \`P * e^(-0.2 * (C+1))\` where P is the number of points assigned to the problem and C is how many correct previous solutions were submitted.
		- Each individual in a team is expected to come up with a solution on its own, without any help from other teammates, submitting the same solution is considered to be plagiarism, we will be monitoring this very seriously.
	- Rankings for teams/individuals with the same amount of points are decided by looking at the time of the latest correct submission (earliest wins)

Have fun and happy problem-solving, if encounter any problems please reach out to us at: *qpa-challenge@snapsoft.hu*.

We also recommend using a desktop computer to use the site for the best experience.

**Registration is going to open at 2022.09.19 15:00**

**The competition will be closed at 2022.09.25 23:59:59, and the official winners will be announced after the anti-cheating screening**
`;


export const HomePage: React.FC = () => {
	return (
		<PageLayout>
			<Markdown
				textContents={pageContent}
			/>
		</PageLayout>
	);
};

import React, {PropsWithChildren} from "react";
import styles from "./Markdown.module.scss";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import rehypeRaw from "rehype-raw";

export interface MarkdownProps extends PropsWithChildren{
    className?: string;
    textContents?: string;
}

export const Markdown: React.FC<MarkdownProps> = (props) => {
    const classes = classNames(styles.Markdown, props.className);
    if (props.children) {
        return (
            <div className={classes}>
                <ReactMarkdown
                    skipHtml={false}
                    rehypePlugins={[rehypeRaw]}
                >
                    {props.children as unknown as any}
                </ReactMarkdown>
            </div>
        );
    }

    return (
        <div className={classes}>
            <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={props.textContents || ""}
            />
        </div>
    );
};
